import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

const ImageContainer = styled.div`
  width: 50%;
  height: 50%;
  display: block;
  margin: auto;
  margin-bottom: 32px;
`

const RecipeHeader = styled.div`
  height: 50px;
  display: flex;
  padding-left: 24px;
  align-items: center;
  text-decoration: none;
  & :before {
    content: "<";
    margin-right: 8px;
  }
  & a {
    font-size: 18px;
    text-decoration: none;
  }
`

const RecipeContainer = styled.div`
  & h1 {
    text-align: center;
    margin-bottom: 8px;
  }
  & h2 {
    text-align: center;
    font-size: 14px;
  }
`

export default function Template({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark || {}
  let featuredImgFluid = frontmatter?.featuredImage?.childImageSharp?.fluid
  return (
    <React.Fragment>
      <RecipeHeader>
        <Link to="/">Back to home</Link>
      </RecipeHeader>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <RecipeContainer>
          <h1>{frontmatter && frontmatter.title}</h1>
          <h2>{frontmatter && frontmatter.date}</h2>
          {featuredImgFluid && (
            <ImageContainer>
              <Img fluid={featuredImgFluid} />
            </ImageContainer>
          )}
          <div
            className="recipe-content"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        </RecipeContainer>
      </div>
    </React.Fragment>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
